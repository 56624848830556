import React, { useEffect } from "react"
import { Box, Flex, Text } from "rebass"
import io from "socket.io-client"
import { css } from "emotion"

const socket = io(`https://rewave.herokuapp.com`, {
  reconnectionDelay: 1000,
  reconnection: true,
  reconnectionAttempts: 10,
  transports: ["websocket"],
  agent: false,
  upgrade: false,
  rejectUnauthorized: false,
})

const Joypad = () => {
  const [isPairingModalOpen, setIsPairingModalOpen] = React.useState(true)
  const [isPairingErrored, setIsPairingErrored] = React.useState(false)
  const [isLoadingKeyphrase, setIsLoadingKeyphrase] = React.useState(false)
  const [keyphrase, setKeyphrase] = React.useState("")

  const gameAction = (type, key) => {
    socket.emit(type, {
      keypress: key,
    })
  }

  useEffect(() => {
    document.body.style.backgroundColor = "#26272c"
    document.body.style.position = "fixed"
    document.body.style.overflow = "hidden"
    document.getElementById("spinup-container").style.display = "none"

    const params = new URLSearchParams(window.location.search)

    document.addEventListener(
      "touchmove",
      event => {
        if (event.scale !== 1) {
          event.preventDefault()
        }
      },
      false
    )

    if (params.get("code")) {
      setKeyphrase(params.get("code"))

      socket.emit("auth", {
        keyphrase: params.get("code"),
      })
    }

    socket.on("paired", () => {
      setIsPairingModalOpen(false)
      setIsLoadingKeyphrase(false)
    })

    socket.on("pairing_error", () => {
      setIsLoadingKeyphrase(false)
      setIsPairingErrored(true)
    })
  }, [])

  return (
    <Box
      className={css`
        * {
          touch-action: manipulation;
        }
      `}
    >
      <Flex
        className={css`
          position: fixed;
          -webkit-overflow-scrolling: touch;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 8px;
          background-color: #26272c;

          .directions {
            width: 150px;

            .joypad-button {
              width: 50px;
              height: 50px;
              background-color: #26272c;

              &.up {
                position: relative;
                top: 3px;
                border-top-left-radius: 0.65em;
                border-top-right-radius: 0.65em;
                border-left: 3px solid #cdccd1;
                border-top: 3px solid #cdccd1;
                border-right: 3px solid #cdccd1;

                &:active {
                  background: linear-gradient(0deg, #26272c 0%, #444444 100%);
                }
              }

              &.left {
                position: relative;
                left: 3px;
                border-top-left-radius: 0.65em;
                border-bottom-left-radius: 0.65em;
                border-bottom: 3px solid #cdccd1;
                border-top: 3px solid #cdccd1;
                border-left: 3px solid #cdccd1;

                &:active {
                  background: linear-gradient(-90deg, #26272c 0%, #444444 100%);
                }
              }

              &.center {
                display: flex;
                align-items: center;
                justify-content: center;

                > div {
                  width: 40px;
                  height: 40px;
                  border-radius: 40px;
                  background: radial-gradient(#212121, #424242);
                }
              }

              &.right {
                position: relative;
                right: 3px;
                border-top-right-radius: 0.65em;
                border-bottom-right-radius: 0.65em;
                border-bottom: 3px solid #cdccd1;
                border-top: 3px solid #cdccd1;
                border-right: 3px solid #cdccd1;

                &:active {
                  background: linear-gradient(90deg, #26272c 0%, #444444 100%);
                }
              }

              &.bottom {
                position: relative;
                bottom: 3px;
                border-bottom-left-radius: 0.65em;
                border-bottom-right-radius: 0.65em;
                border-left: 3px solid #cdccd1;
                border-bottom: 3px solid #cdccd1;
                border-right: 3px solid #cdccd1;

                &:active {
                  background: linear-gradient(180deg, #26272c 0%, #444444 100%);
                }
              }

              &:active:not(.center) {
                background-color: #525259;
              }
            }
          }
        `}
        justifyContent="space-around"
        alignItems="center"
      >
        <Box mr={3}>
          <Flex className="directions" flexWrap="wrap">
            <Box width={1 / 3} />
            <Box width={1 / 3} className="joypad-button up" />
            <Box width={1 / 3} />
            <Box
              width={1 / 3}
              className="joypad-button left"
              onTouchStart={() => gameAction("keyDown", "LEFT")}
              onTouchEnd={() => gameAction("keyUp", "LEFT")}
            />
            <Box width={1 / 3} className="joypad-button center">
              <Box />
            </Box>
            <Box
              width={1 / 3}
              className="joypad-button right"
              onTouchStart={() => gameAction("keyDown", "RIGHT")}
              onTouchEnd={() => gameAction("keyUp", "RIGHT")}
            />
            <Box width={1 / 3} />
            <Box width={1 / 3} className="joypad-button bottom" />
            <Box width={1 / 3} />
          </Flex>
        </Box>

        <Box mr={3}>
          <Flex
            className={css`
              position: relative;
              justify-content: center;

              .start-button {
                background-color: #26272c;
                border: 10px solid #cdccd1;
                width: 60px;
                height: 30px;
                border-radius: 3px;
                cursor: pointer;

                &:active {
                  background-color: #111111;
                }
              }
            `}
          >
            <Box
              className="start-button"
              onClick={() => gameAction("keyDown", "ENTER")}
            />

            <Text
              className={css`
                position: absolute;
                top: 100%;
                font-weight: 600;
                color: #fff;
              `}
            >
              START
            </Text>
          </Flex>
        </Box>

        <Box>
          <Flex
            className={css`
              justify-content: center;

              > div {
                display: inline-flex;
                margin-right: 5px;
                padding: 3px;
                border-radius: 3px;
                background-color: #cdccd1;

                button {
                  position: relative;
                  outline: none !important;
                  width: 50px;
                  height: 50px;
                  border-radius: 50px;
                  background-color: #ff0000;
                  border: none;

                  &:active {
                    top: 1px;
                    left: 1px;
                  }
                }
              }
            `}
          >
            <Box>
              <button
                onTouchStart={() => gameAction("keyDown", "SPACE")}
                onTouchEnd={() => gameAction("keyUp", "SPACE")}
              />
            </Box>

            {/* <Box>
              <button
                onTouchStart={() => gameAction("keyDown", "SPACE")}
                onTouchEnd={() => gameAction("keyUp", "SPACE")}
              />
            </Box> */}
          </Flex>
        </Box>
      </Flex>

      {isPairingModalOpen && (
        <Flex
          className={css`
            position: fixed;
            -webkit-overflow-scrolling: touch;
            font-family: monospace;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 8px;
            background-color: #26272c;
            color: #fff;
            z-index: 2;
          `}
        >
          {isPairingErrored ? (
            <Text mb={5} textAlign="center" color="#d70000">
              There was an error verifying the code, please double check it!
            </Text>
          ) : null}

          <Text textAlign="center">
            Visit this page from your computer in order to obtain the auth code
            to insert below.
          </Text>

          <Text textAlign="center" mt={3}>
            Once you inserted the code you'll be able to play Spinup Invaders
            using your smartphone as controller.
          </Text>

          <Box mt={7} width="70%">
            <input
              className={css`
                padding: 8px;
                font-size: 18px;
                border-radius: 0;
                border: none;
                width: 100%;
                font-family: monospace;
                text-align: center;
              `}
              placeholder="Scrivi il codice"
              type="text"
              value={keyphrase}
              onChange={e => setKeyphrase(e.target.value)}
            />

            <button
              className={css`
                width: 100%;
                background-color: #10970e;
                padding: 8px;
                font-family: monospace;
                font-size: 18px;
                border: none;
              `}
              disabled={isLoadingKeyphrase}
              type="button"
              onClick={() => {
                socket.emit("auth", {
                  keyphrase,
                })

                setIsPairingErrored(false)
                setIsLoadingKeyphrase(true)
              }}
            >
              Send
            </button>
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default Joypad
